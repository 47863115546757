import "./conta.css"
import Navbar from "../menu/menu";
import axios from 'axios';
import {useState} from "react";
import Items from "./items";
import {useNavigate} from "react-router-dom";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {post} from "axios";
export default function ContaEdgar() {


    const currentDate = new Date().toISOString().split('T')[0];




    const submitText = "Inregistreaza"
    const [textValue, setTextValue] = useState('');
    const [text, setText] = useState('');

    const handleChange = (event) => {
        setText(event.target.value);
    };

    const [data, setData] = useState(currentDate)
    const [cafenea, setCafenea] = useState()
    const [cafea, setCafea] = useState()
    const [lapte, setLapte] = useState()
    const [alternativ, setAlternativ] = useState()
    const [cash, setCash] = useState()
    const [card, setCard] = useState()
    const [bonuri, setBonuri] = useState()

    //Const for the list of order:

    const [cafeaKg, setCafeaKg] = useState();
    const [cafeaFaraCofeinaKg, setCafeaFaraCofeinaKg] = useState();
    const [cafeaCutie250Gr, setCafeaCutie250Gr] = useState();
    const [lapteCutie, setLapteCutie] = useState();
    const [lapteFaraLactoza, setLapteFaraLactoza] = useState();
    const [lapteAlternativ, setLapteAlternativ] = useState();
    const [bananaBread, setBananaBread] = useState();
    const [pungiAlbeServireBB, setPungiAlbeServireBB] = useState();
    const [cookies, setCookies] = useState();
    const [pungiAlbeServireCK, setPungiAlbeServireCK] = useState();
    const [roleMariCasaMarcat, setRoleMariCasaMarcat] = useState();
    const [roleMiciPOS, setRoleMiciPOS] = useState();
    const [sandwiches, setSandwiches] = useState();
    const [inghetata, setInghetata] = useState();
    const [gheata, setGheata] = useState();
    const [toppingCaramel, setToppingCaramel] = useState();
    const [toppingCiocolata, setToppingCiocolata] = useState();
    const [frisca, setFrisca] = useState();
    const [ciocolataNeagra, setCiocolataNeagra] = useState();
    const [ciocolataAlba, setCiocolataAlba] = useState();
    const [ciocolataCuLapte, setCiocolataCuLapte] = useState();
    const [machaOrganic, setMachaOrganic] = useState();
    const [machaSweetened, setMachaSweetened] = useState();
    const [powerChaiCuEspresso, setPowerChaiCuEspresso] = useState();
    const [spicedChai, setSpicedChai] = useState();
    const [chichoryLatte, setChichoryLatte] = useState();
    const [paletineLemn, setPaletineLemn] = useState();
    const [zaharAlb, setZaharAlb] = useState();
    const [zaharBrun, setZaharBrun] = useState();
    const [laveteMicrofibra, setLaveteMicrofibra] = useState();
    const [laveteBurete, setLaveteBurete] = useState();
    const [bureteAbraziv, setBureteAbraziv] = useState();
    const [manusiTransparente, setManusiTransparente] = useState();
    const [bicarbonat, setBicarbonat] = useState();
    const [otet, setOtet] = useState();
    const [detergentLichidMaini, setDetergentLichidMaini] = useState();
    const [detergentLichidPodele, setDetergentLichidPodele] = useState();
    const [spirt, setSpirt] = useState();
    const [mixUleiuriEsentiale, setMixUleiuriEsentiale] = useState();
    const [detergentSpalatGrupuri, setDetergentSpalatGrupuri] = useState();
    const [saciGunoi120L, setSaciGunoi120L] = useState();
    const [saciGunoi60L, setSaciGunoi60L] = useState();
    const [servetele, setServetele] = useState();
    const [bomboaneCafea, setBomboaneCafea] = useState();
    const [pahareEspresso4Oz, setPahareEspresso4Oz] = useState();
    const [capaceEspresso4Oz, setCapaceEspresso4Oz] = useState();
    const [pahareDoppio7_7Oz, setPahareDoppio7_7Oz] = useState();
    const [capaceDoppio7_7Oz, setCapaceDoppio7_7Oz] = useState();
    const [pahareS8Oz, setPahareS8Oz] = useState();
    const [capaceS8Oz, setCapaceS8Oz] = useState();
    const [pahareM12Oz, setPahareM12Oz] = useState();
    const [pahareL16Oz, setPahareL16Oz] = useState();
    const [pahareXL22Oz, setPahareXL22Oz] = useState();
    const [capaceM12_16_20Oz, setCapaceM12_16_20Oz] = useState();
    const [pahareFrappeM, setPahareFrappeM] = useState();
    const [pahareFrappeL, setPahareFrappeL] = useState();
    const [capaceFrappeM_L, setCapaceFrappeM_L] = useState();
    const [paieGroase, setPaieGroase] = useState();
    const [paieSubtiri, setPaieSubtiri] = useState();
    const [macrons, setMacrons] = useState();
    const [cremeBrulee, setCremeBrulee] = useState();
    const [fistic, setFistic] = useState();
    const [macadamia, setMacadamia] = useState();
    const [hazelnut, setHazelnut] = useState();
    const [roastedHazelnut, setRoastedHazelnut] = useState();
    const [caramelSarat, setCaramelSarat] = useState();
    const [toffee, setToffee] = useState();
    const [irish, setIrish] = useState();
    const [tiramisu, setTiramisu] = useState();
    const [amaretto, setAmaretto] = useState();
    const [menta, setMenta] = useState();
    const [dolceDeLece, setDolceDeLece] = useState();
    const [cookiesChocolate, setCookiesChocolate] = useState();
    const [rom, setRom] = useState();
    const [marshmallow, setMarshmallow] = useState();
    const [pumpkinSpice, setPumpkinSpice] = useState();
    const [cinnamon, setCinnamon] = useState();
    const [mojito, setMojito] = useState();
    const [faraZaharVanilie, setFaraZaharVanilie] = useState();
    const [faraZaharCaramel, setFaraZaharCaramel] = useState();
    const [vanilie, setVanilie] = useState();
    const [cocos, setCocos] = useState();
    const [caramel, setCaramel] = useState();
    const [caramel25cl, setCaramel25cl] = useState();
    const [caramelSarat25cl, setCaramelSarat25cl] = useState();
    const [vanilie25cl, setVanilie25cl] = useState();
    const [cocos25cl, setCocos25cl] = useState();
    const [tiramisu25cl, setTiramisu25cl] = useState();
    const [irish25cl, setIrish25cl] = useState();
    const [hazelnut25cl, setHazelnut25cl] = useState();
    const [fentimansCherryCola, setFentimansCherryCola] = useState();
    const [fentimansCola, setFentimansCola] = useState();
    const [fentimansRoseLemonade, setFentimansRoseLemonade] = useState();
    const [mellowOrange, setMellowOrange] = useState();
    const [mellowMango, setMellowMango] = useState();
    const [mellowMere, setMellowMere] = useState();
    const [fritzCola, setFritzCola] = useState();
    const [fritzPortocale, setFritzPortocale] = useState();
    const [jumexBananeCapsuni, setJumexBananeCapsuni] = useState();
    const [jumexCocosAnanas, setJumexCocosAnanas] = useState();
    const [jumexMango, setJumexMango] = useState();
    const [rubiconMango, setRubiconMango] = useState();
    const [rubiconPomegranate, setRubiconPomegranate] = useState();
    const [rubiconPassionFruit, setRubiconPassionFruit] = useState();
    const [drPepperRegular, setDrPepperRegular] = useState();
    const [drPepperCherry, setDrPepperCherry] = useState();
    const [bundabergGingerBeer, setBundabergGingerBeer] = useState();
    const [energizante, setEnergizante] = useState();
    const [figa, setFiga] = useState();
    const [apaTonica, setApaTonica] = useState();
    const [apaPlata, setApaPlata] = useState();
    const [apaMinerala, setApaMinerala] = useState();

    const [ceaiPlicEnglishBreakfast, setCeaiPlicEnglishBreakfast] = useState();
    const [ceaiPlicEarlGrey, setCeaiPlicEarlGrey] = useState();
    const [ceaiPlicMenta, setCeaiPlicMenta] = useState();
    const [ceaiPlicJasmin, setCeaiPlicJasmin] = useState();
    const [ceaiPlicVerde, setCeaiPlicVerde] = useState();
    const [ceaiPlicChamomile, setCeaiPlicChamomile] = useState();
    const [ceaiPlicPiersicaMar, setCeaiPlicPiersicaMar] = useState();
    const [ceaiPlicWildBerries, setCeaiPlicWildBerries] = useState();

    const [scortisoara, setScortisoara] = useState();
    const [nucsoara, setNucsoara] = useState();
    const [cocosCondiment, setCocosCondiment] = useState();
    const [ghimbir, setGhimbir] = useState();
    const [cayennePepper, setCayennePepper] = useState();
    const [turmeric, setTurmeric] = useState();
    const [stevia, setStevia] = useState();
    const [zaharina, setZaharina] = useState();
    const [cardamom, setCardamom] = useState();


    ///// Rusani /////

    const [hazelnutAlmonds, setHazelnutAlmonds] = useState();
    const [hazelnutCashews, setHazelnutCashews] = useState();
    const [almondsTahini, setAlmondsTahini] = useState();
    const [almondsAloe, setAlmondsAloe] = useState();
    const [almondsPlums, setAlmondsPlums] = useState();
    const [almondsCocoa, setAlmondsCocoa] = useState();
    const [almondsFigs, setAlmondsFigs] = useState();


    //// Flap Jack ////

    const [oatCherryYogurt, setOatCherryYogurt] = useState();
    const [oatTripleChocolate, setOatTripleChocolate] = useState();
    const [oatStrawberryCheesecake, setOatStrawberryCheesecake] = useState();
    const [oatBlueberries, setOatBlueberries] = useState();
    const [oatWalnuts, setOatWalnuts] = useState();
    const [oatBanana, setOatBanana] = useState();
    const [oatChocoCoconut, setOatChocoCoconut] = useState();
    const [oatChocoChip, setOatChocoChip] = useState();
    const [oatCookiesCream, setCookiesCream] = useState();


    ///// Keto /////

    const [ketoCookiesCream, setKetoCookiesCream] = useState();
    const [activeDoubleChocolate, setActiveDoubleChocolate] = useState();
    const [boostChocolateChip, setBoostChocolateChip] = useState();
    const [boostDoubleChocolateBlueberry, setBoostDoubleChocolateBlueberry] = useState();
    const [slimCookiesCream, setSlimCookiesCream] = useState();
    const [boostDoubleChocolateChip, setBoostDoubleChocolateChip] = useState();
    const [deluxeChocolateChip, setDeluxeChocolateChip] = useState();
    const [deluxeCookiesCream, setDeluxeCookiesCream] = useState();

    let suma = 0
    const navigate = useNavigate()

    const [showForm, setShowForm] = useState(false);




    const handleTextareaChange = (e) => {
        setTextValue(e.target.value);
    };

    const handleButtonClick = () => {
        setShowForm(!showForm);
    };

    const changeData = e => {
        setData(e.target.value)
    }

    const changeCafenea = e => {
        setCafenea(e.target.value)
    }


    const changeCafea = e => {
        setCafea(e.target.value)
    }

    const changeLapte = e => {
        setLapte(e.target.value)
    }

    const changeAlternativ = e => {
        setAlternativ(e.target.value)
    }

    const changeCash = e => {
        setCash(e.target.value)
    }

    const changeCard = e => {
        setCard(e.target.value)
    }

    const changeBonuri = e => {
        setBonuri(e.target.value)
    }

    const changeCafeaKg = e => {
        setCafeaKg(e.target.value)
    }

    const changeLapteCutie = e => {
        setLapteCutie(e.target.value)
    }


    const changeCafeaFaraCofeinaKg = e => {
        setCafeaFaraCofeinaKg(e.target.value);
    };

    const changeCafeaCutie250Gr = e => {
        setCafeaCutie250Gr(e.target.value);
    };


    const changeLapteFaraLactoza = e => {
        setLapteFaraLactoza(e.target.value);
    };

    const changeLapteAlternativ = e => {
        setLapteAlternativ(e.target.value);
    };

    const changeBananaBread = e => {
        setBananaBread(e.target.value);
    };

    const changePungiAlbeServireBB = e => {
        setPungiAlbeServireBB(e.target.value);
    };

    const changeCookies = e => {
        setCookies(e.target.value);
    };

    const changePungiAlbeServireCK = e => {
        setPungiAlbeServireCK(e.target.value);
    };

    const changeRoleMariCasaMarcat = e => {
        setRoleMariCasaMarcat(e.target.value);
    };

    const changeRoleMiciPOS = e => {
        setRoleMiciPOS(e.target.value);
    };

    const changeSandwiches = e => {
        setSandwiches(e.target.value);
    };

    const changeInghetata = e => {
        setInghetata(e.target.value);
    };

    const changeGheata = e => {
        setGheata(e.target.value);
    };

    const changeToppingCaramel = e => {
        setToppingCaramel(e.target.value);
    };

    const changeToppingCiocolata = e => {
        setToppingCiocolata(e.target.value);
    };

    const changeFrisca = e => {
        setFrisca(e.target.value);
    };

    const changeCiocolataNeagra = e => {
        setCiocolataNeagra(e.target.value);
    };

    const changeCiocolataAlba = e => {
        setCiocolataAlba(e.target.value);
    };

    const changeCiocolataCuLapte = e => {
        setCiocolataCuLapte(e.target.value);
    };

    const changeMachaOrganic = e => {
        setMachaOrganic(e.target.value);
    };

    const changeMachaSweetened = e => {
        setMachaSweetened(e.target.value);
    };

    const changePowerChaiCuEspresso = e => {
        setPowerChaiCuEspresso(e.target.value);
    };

    const changeSpicedChai = e => {
        setSpicedChai(e.target.value);
    };

    const changeChichoryLatte = e => {
        setChichoryLatte(e.target.value);
    };

    const changePaletineLemn = e => {
        setPaletineLemn(e.target.value);
    };

    const changeZaharAlb = e => {
        setZaharAlb(e.target.value);
    };

    const changeZaharBrun = e => {
        setZaharBrun(e.target.value);
    };

    const changeLaveteMicrofibra = e => {
        setLaveteMicrofibra(e.target.value);
    };

    const changeLaveteBurete = e => {
        setLaveteBurete(e.target.value);
    };

    const changeBureteAbraziv = e => {
        setBureteAbraziv(e.target.value);
    };

    const changeManusiTransparente = e => {
        setManusiTransparente(e.target.value);
    };

    const changeBicarbonat = e => {
        setBicarbonat(e.target.value);
    };

    const changeOtet = e => {
        setOtet(e.target.value);
    };

    const changeDetergentLichidMaini = e => {
        setDetergentLichidMaini(e.target.value);
    };

    const changeDetergentLichidPodele = e => {
        setDetergentLichidPodele(e.target.value);
    };

    const changeSpirt = e => {
        setSpirt(e.target.value);
    };

    const changeMixUleiuriEsentiale = e => {
        setMixUleiuriEsentiale(e.target.value);
    };

    const changeDetergentSpalatGrupuri = e => {
        setDetergentSpalatGrupuri(e.target.value);
    };

    const changeSaciGunoi120L = e => {
        setSaciGunoi120L(e.target.value);
    };

    const changeSaciGunoi60L = e => {
        setSaciGunoi60L(e.target.value);
    };

    const changeServetele = e => {
        setServetele(e.target.value);
    };

    const changeBomboaneCafea = e => {
        setBomboaneCafea(e.target.value);
    };

    const changePahareEspresso4Oz = e => {
        setPahareEspresso4Oz(e.target.value);
    };

    const changeCapaceEspresso4Oz = e => {
        setCapaceEspresso4Oz(e.target.value);
    };

    const changePahareDoppio7_7Oz = e => {
        setPahareDoppio7_7Oz(e.target.value);
    };

    const changeCapaceDoppio7_7Oz = e => {
        setCapaceDoppio7_7Oz(e.target.value);
    };

    const changePahareS8Oz = e => {
        setPahareS8Oz(e.target.value);
    };

    const changeCapaceS8Oz = e => {
        setCapaceS8Oz(e.target.value);
    };

    const changePahareM12Oz = e => {
        setPahareM12Oz(e.target.value);
    };

    const changePahareL16Oz = e => {
        setPahareL16Oz(e.target.value);
    };

    const changePahareXL22Oz = e => {
        setPahareXL22Oz(e.target.value);
    };

    const changeCapaceM12_16_20Oz = e => {
        setCapaceM12_16_20Oz(e.target.value);
    };

    const changePahareFrappeM = e => {
        setPahareFrappeM(e.target.value);
    };

    const changePahareFrappeL = e => {
        setPahareFrappeL(e.target.value);
    };

    const changeCapaceFrappeM_L = e => {
        setCapaceFrappeM_L(e.target.value);
    };

    const changePaieGroase = e => {
        setPaieGroase(e.target.value);
    };

    const changePaieSubtiri = e => {
        setPaieSubtiri(e.target.value);
    };

    const changeMacrons = e => {
        setMacrons(e.target.value);
    };

    const changeCremeBrulee = e => {
        setCremeBrulee(e.target.value);
    };

    const changeFistic = e => {
        setFistic(e.target.value);
    };

    const changeMacadamia = e => {
        setMacadamia(e.target.value);
    };

    const changeHazelnut = e => {
        setHazelnut(e.target.value);
    };

    const changeRoastedHazelnut = e => {
        setRoastedHazelnut(e.target.value);
    };

    const changeCaramelSarat = e => {
        setCaramelSarat(e.target.value);
    };

    const changeToffee = e => {
        setToffee(e.target.value);
    };

    const changeIrish = e => {
        setIrish(e.target.value);
    };

    const changeTiramisu = e => {
        setTiramisu(e.target.value);
    };

    const changeAmaretto = e => {
        setAmaretto(e.target.value);
    };

    const changeMenta = e => {
        setMenta(e.target.value);
    };

    const changeDolceDeLece = e => {
        setDolceDeLece(e.target.value);
    };

    const changeCookiesChocolate = e => {
        setCookiesChocolate(e.target.value);
    };

    const changeRom = e => {
        setRom(e.target.value);
    };

    const changeMarshmallow = e => {
        setMarshmallow(e.target.value);
    };

    const changePumpkinSpice = e => {
        setPumpkinSpice(e.target.value);
    };

    const changeCinnamon = e => {
        setCinnamon(e.target.value);
    };

    const changeMojito = e => {
        setMojito(e.target.value);
    };

    const changeFaraZaharVanilie = e => {
        setFaraZaharVanilie(e.target.value);
    };

    const changeFaraZaharCaramel = e => {
        setFaraZaharCaramel(e.target.value);
    };

    const changeVanilie = e => {
        setVanilie(e.target.value);
    };

    const changeCocos = e => {
        setCocos(e.target.value);
    };

    const changeCaramel = e => {
        setCaramel(e.target.value);
    };

    const changeCaramel25cl = e => {
        setCaramel25cl(e.target.value);
    };

    const changeCaramelSarat25cl = e => {
        setCaramelSarat25cl(e.target.value);
    };

    const changeVanilie25cl = e => {
        setVanilie25cl(e.target.value);
    };

    const changeCocos25cl = e => {
        setCocos25cl(e.target.value);
    };

    const changeTiramisu25cl = e => {
        setTiramisu25cl(e.target.value);
    };

    const changeIrish25cl = e => {
        setIrish25cl(e.target.value);
    };

    const changeHazelnut25cl = e => {
        setHazelnut25cl(e.target.value);
    };

    const changeFentimansCherryCola = e => {
        setFentimansCherryCola(e.target.value);
    };

    const changeFentimansCola = e => {
        setFentimansCola(e.target.value);
    };

    const changeFentimansRoseLemonade = e => {
        setFentimansRoseLemonade(e.target.value);
    };

    const changeMellowOrange = e => {
        setMellowOrange(e.target.value);
    };

    const changeMellowMango = e => {
        setMellowMango(e.target.value);
    };

    const changeMellowMere = e => {
        setMellowMere(e.target.value);
    };

    const changeFritzCola = e => {
        setFritzCola(e.target.value);
    };

    const changeFritzPortocale = e => {
        setFritzPortocale(e.target.value);
    };

    const changeJumexBananeCapsuni = e => {
        setJumexBananeCapsuni(e.target.value);
    };

    const changeJumexCocosAnanas = e => {
        setJumexCocosAnanas(e.target.value);
    };

    const changeJumexMango = e => {
        setJumexMango(e.target.value);
    };

    const changeRubiconMango = e => {
        setRubiconMango(e.target.value);
    };

    const changeRubiconPomegranate = e => {
        setRubiconPomegranate(e.target.value);
    };

    const changeRubiconPassionFruit = e => {
        setRubiconPassionFruit(e.target.value);
    };

    const changeDrPepperRegular = e => {
        setDrPepperRegular(e.target.value);
    };

    const changeDrPepperCherry = e => {
        setDrPepperCherry(e.target.value);
    };

    const changeBundabergGingerBeer = e => {
        setBundabergGingerBeer(e.target.value);
    };

    const changeEnergizante = e => {
        setEnergizante(e.target.value);
    };

    const changeFiga = e => {
        setFiga(e.target.value);
    };

    const changeApaTonica = e => {
        setApaTonica(e.target.value);
    };

    const changeApaPlata = e => {
        setApaPlata(e.target.value);
    };

    const changeApaMinerala = e => {
        setApaMinerala(e.target.value);
    };

    const changeCeaiPlicEnglishBreakfast = e => {
        setCeaiPlicEnglishBreakfast(e.target.value);
    };

    const changeCeaiPlicEarlGrey = e => {
        setCeaiPlicEarlGrey(e.target.value);
    };

    const changeCeaiPlicMenta = e => {
        setCeaiPlicMenta(e.target.value);
    };

    const changeCeaiPlicJasmin = e => {
        setCeaiPlicJasmin(e.target.value);
    };

    const changeCeaiPlicVerde = e => {
        setCeaiPlicVerde(e.target.value);
    };

    const changeCeaiPlicChamomile = e => {
        setCeaiPlicChamomile(e.target.value);
    };

    const changeCeaiPlicPiersicaMar = e => {
        setCeaiPlicPiersicaMar(e.target.value);
    };

    const changeCeaiPlicWildBerries = e => {
        setCeaiPlicWildBerries(e.target.value);
    };

    const changeScortisoara = e => {
        setScortisoara(e.target.value);
    };

    const changeNucsoara = e => {
        setNucsoara(e.target.value);
    };

    const changeCocosCondiment = e => {
        setCocosCondiment(e.target.value);
    };

    const changeGhimbir = e => {
        setGhimbir(e.target.value);
    };

    const changeCayennePepper = e => {
        setCayennePepper(e.target.value);
    };

    const changeTurmeric = e => {
        setTurmeric(e.target.value);
    };

    const changeStevia = e => {
        setStevia(e.target.value);
    };

    const changeZaharina = e => {
        setZaharina(e.target.value);
    };

    const changeCardamom = e => {
        setCardamom(e.target.value);
    };

    const changeHazelnutAlmonds = e => {
        setHazelnutAlmonds(e.target.value);
    };

    const changeHazelnutCashews = e => {
        setHazelnutCashews(e.target.value);
    };

    const changeAlmondsTahini = e => {
        setAlmondsTahini(e.target.value);
    };

    const changeAlmondsAloe = e => {
        setAlmondsAloe(e.target.value);
    };

    const changeAlmondsPlums = e => {
        setAlmondsPlums(e.target.value);
    };

    const changeAlmondsCocoa = e => {
        setAlmondsCocoa(e.target.value);
    };

    const changeAlmondsFigs = e => {
        setAlmondsFigs(e.target.value);
    };

    const changeOatCherryYogurt = e => {
        setOatCherryYogurt(e.target.value);
    };

    const changeOatTripleChocolate = e => {
        setOatTripleChocolate(e.target.value);
    };

    const changeOatStrawberryCheesecake = e => {
        setOatStrawberryCheesecake(e.target.value);
    };

    const changeOatBlueberries = e => {
        setOatBlueberries(e.target.value);
    };

    const changeOatWalnuts = e => {
        setOatWalnuts(e.target.value);
    };

    const changeOatBanana = e => {
        setOatBanana(e.target.value);
    };

    const changeOatChocoCoconut = e => {
        setOatChocoCoconut(e.target.value);
    };

    const changeOatChocoChip = e => {
        setOatChocoChip(e.target.value);
    };

    const changeCookiesCream = e => {
        setCookiesCream(e.target.value);
    };

    const changeKetoCookiesCream = e => {
        setKetoCookiesCream(e.target.value);
    };

    const changeActiveDoubleChocolate = e => {
        setActiveDoubleChocolate(e.target.value);
    };

    const changeBoostChocolateChip = e => {
        setBoostChocolateChip(e.target.value);
    };

    const changeBoostDoubleChocolateBlueberry = e => {
        setBoostDoubleChocolateBlueberry(e.target.value);
    };

    const changeSlimCookiesCream = e => {
        setSlimCookiesCream(e.target.value);
    };

    const changeBoostDoubleChocolateChip = e => {
        setBoostDoubleChocolateChip(e.target.value);
    };

    const changeDeluxeChocolateChip = e => {
        setDeluxeChocolateChip(e.target.value);
    };

    const changeDeluxeCookiesCream = e => {
        setDeluxeCookiesCream(e.target.value);
    };



    const itemHandlers = {
        Cafea: changeCafeaKg,
        Cafea_Decaf: changeCafeaFaraCofeinaKg,
        Cafea_250: changeCafeaCutie250Gr,
        Lapte: changeLapteCutie,
        Lactose_Free: changeLapteFaraLactoza,
        Alternativ: changeLapteAlternativ,
        Banana_Bread: changeBananaBread,
        Pungi_BB: changePungiAlbeServireBB,
        Cookies: changeCookies,
        Pungi_CK: changePungiAlbeServireCK,
        Role_Casa: changeRoleMariCasaMarcat,
        Role_POS: changeRoleMiciPOS,
        Sandwiches: changeSandwiches,
        Inghetata: changeInghetata,
        Gheata: changeGheata,
        Top_Caramel: changeToppingCaramel,
        Top_Ciocolata: changeToppingCiocolata,
        Frisca: changeFrisca,
        Cioc_Neagra: changeCiocolataNeagra,
        Cioc_Alba: changeCiocolataAlba,
        Cioc_Lapte: changeCiocolataCuLapte,
        Macha_Org: changeMachaOrganic,
        Macha_Sweet: changeMachaSweetened,
        Power_Espresso: changePowerChaiCuEspresso,
        Spiced_Chai: changeSpicedChai,
        Chichory_Lat: changeChichoryLatte,
        PaletineLemn: changePaletineLemn,
        Zahar_Alb: changeZaharAlb,
        Zahar_Brun: changeZaharBrun,
        Lav_Micro: changeLaveteMicrofibra,
        Lav_Burete: changeLaveteBurete,
        Burete_Abr: changeBureteAbraziv,
        Manusi: changeManusiTransparente,
        Bicarbonat: changeBicarbonat,
        Otet: changeOtet,
        Det_Maini: changeDetergentLichidMaini,
        Det_Podele: changeDetergentLichidPodele,
        Spirt: changeSpirt,
        Uleiuri_Es: changeMixUleiuriEsentiale,
        Det_Grupuri: changeDetergentSpalatGrupuri,
        Saci_120L: changeSaciGunoi120L,
        Saci_60L: changeSaciGunoi60L,
        Servetele: changeServetele,
        Bomb_Cafea: changeBomboaneCafea,
        PaharEsp4Oz: changePahareEspresso4Oz,
        Capac_Esp4Oz: changeCapaceEspresso4Oz,
        Pahar_Dop7Oz: changePahareDoppio7_7Oz,
        CapacDop7Oz: changeCapaceDoppio7_7Oz,
        PaharS8Oz: changePahareS8Oz,
        CapacS8Oz: changeCapaceS8Oz,
        PaharM12Oz: changePahareM12Oz,
        PaharL16Oz: changePahareL16Oz,
        PaharXL22Oz: changePahareXL22Oz,
        CapacM_20Oz: changeCapaceM12_16_20Oz,
        Pahare_FrapM: changePahareFrappeM,
        Pahare_FrapL: changePahareFrappeL,
        CapaceFrap: changeCapaceFrappeM_L,
        PaieGroase: changePaieGroase,
        PaieSubtiri: changePaieSubtiri,
        Macrons: changeMacrons,
        CremeBrulee: changeCremeBrulee,
        Fistic: changeFistic,
        Macadamia: changeMacadamia,
        Hazelnut: changeHazelnut,
        RoastedHazelnut: changeRoastedHazelnut,
        CaramelSarat: changeCaramelSarat,
        Toffee: changeToffee,
        Irish: changeIrish,
        Tiramisu: changeTiramisu,
        Amaretto: changeAmaretto,
        Menta: changeMenta,
        DolceDeLece: changeDolceDeLece,
        CookiesChocolate: changeCookiesChocolate,
        Rom: changeRom,
        Marshmallow: changeMarshmallow,
        PumpkinSpice: changePumpkinSpice,
        Cinnamon: changeCinnamon,
        Mojito: changeMojito,
        FaraZaharVanilie: changeFaraZaharVanilie,
        FaraZaharCaramel: changeFaraZaharCaramel,
        Vanilie: changeVanilie,
        Cocos: changeCocos,
        Caramel: changeCaramel,
        Caramel25cl: changeCaramel25cl,
        CaramelSarat25cl: changeCaramelSarat25cl,
        Vanilie25cl: changeVanilie25cl,
        Cocos25cl: changeCocos25cl,
        Tiramisu25cl: changeTiramisu25cl,
        Irish25cl: changeIrish25cl,
        Hazelnut25cl: changeHazelnut25cl,
        FentimansCherryCola: changeFentimansCherryCola,
        FentimansCola: changeFentimansCola,
        FentimansRoseLemonade: changeFentimansRoseLemonade,
        MellowOrange: changeMellowOrange,
        MellowMango: changeMellowMango,
        MellowMere: changeMellowMere,
        FritzCola: changeFritzCola,
        FritzPortocale: changeFritzPortocale,
        JumexBananeCapsuni: changeJumexBananeCapsuni,
        JumexCocosAnanas: changeJumexCocosAnanas,
        JumexMango: changeJumexMango,
        RubiconMango: changeRubiconMango,
        RubiconPomegranate: changeRubiconPomegranate,
        RubiconPassionFruit: changeRubiconPassionFruit,
        DrPepperRegular: changeDrPepperRegular,
        DrPepperCherry: changeDrPepperCherry,
        BundabergGingerBeer: changeBundabergGingerBeer,
        Energizante: changeEnergizante,
        Figa: changeFiga,
        ApaTonica: changeApaTonica,
        ApaPlata: changeApaPlata,
        ApaMinerala: changeApaMinerala,
        CeaiPlicEnglishBreakfast: changeCeaiPlicEnglishBreakfast,
        CeaiPlicEarlGrey: changeCeaiPlicEarlGrey,
        CeaiPlicMenta: changeCeaiPlicMenta,
        CeaiPlicJasmin: changeCeaiPlicJasmin,
        CeaiPlicVerde: changeCeaiPlicVerde,
        CeaiPlicChamomile: changeCeaiPlicChamomile,
        CeaiPlicPiersicaMar: changeCeaiPlicPiersicaMar,
        CeaiPlicWildBerries: changeCeaiPlicWildBerries,
        Scortisoara: changeScortisoara,
        Nucsoara: changeNucsoara,
        CocosCondiment: changeCocosCondiment,
        Ghimbir: changeGhimbir,
        CayennePepper: changeCayennePepper,
        Turmeric: changeTurmeric,
        Stevia: changeStevia,
        Zaharina: changeZaharina,
        Cardamom: changeCardamom,
        hazelnutAlmonds: changeHazelnutAlmonds,
        hazelnutCashews: changeHazelnutCashews,
        almondsTahini: changeAlmondsTahini,
        almondsAloe: changeAlmondsAloe,
        almondsPlums: changeAlmondsPlums,
        almondsCocoa: changeAlmondsCocoa,
        almondsFigs: changeAlmondsFigs,
        oatCherryYogurt: changeOatCherryYogurt,
        oatTripleChocolate: changeOatTripleChocolate,
        oatStrawberryCheesecake: changeOatStrawberryCheesecake,
        oatBlueberries: changeOatBlueberries,
        oatWalnuts: changeOatWalnuts,
        oatBanana: changeOatBanana,
        oatChocoCoconut: changeOatChocoCoconut,
        oatChocoChip: changeOatChocoChip,
        cookiesCream: changeCookiesCream,
        ketoCookiesCream: changeKetoCookiesCream,
        activeDoubleChocolate: changeActiveDoubleChocolate,
        boostChocolateChip: changeBoostChocolateChip,
        boostDoubleChocolateBlueberry: changeBoostDoubleChocolateBlueberry,
        slimCookiesCream: changeSlimCookiesCream,
        boostDoubleChocolateChip: changeBoostDoubleChocolateChip,
        deluxeChocolateChip: changeDeluxeChocolateChip,
        deluxeCookiesCream: changeDeluxeCookiesCream,
    };


    const sumaZi = () => {
        suma += (cafea + lapte + alternativ)
        console.log(suma)
        navigate('/plot')
    }

    const itemList = [
        "Cafea",
        "Cafea-Decofeinizata",
        "Cafea-250g",
        "Lapte",
        "Lapte-Fara-Lactoza",
        "Lapte-Alternativ",
        "Banana-Bread",
        "Pungi-Banana-Bread",
        "Cookies",
        "Pungi-Cookies",
        "Role-Casa",
        "Role-POS",
        "Sandwiches",
        "Inghetata",
        "Gheata",
        "Topping-Caramel",
        "Topping-Ciocolata",
        "Frisca",
        "Ciocolata-Neagra",
        "Ciocolata-Alba",
        "Ciocolata-Lapte",
        "Macha-Organic",
        "Macha-Sweetened",
        "Power-Chai",
        "Spiced-Chai",
        "Chichory-Latte",
        "Paletine",
        "Zahar-Alb",
        "Zahar-Brun",
        "Lavete-Micro",
        "Lavete-Burete",
        "Burete-Abraziv",
        "Manusi",
        "Bicarbonat",
        "Otet",
        "Detergent-Maini",
        "Detergent-Podele",
        "Spirt",
        "Uleiuri-Esentiale",
        "Detergent-Grupuri",
        "Saci-120L",
        "Saci-60L",
        "Servetele",
        "Bomboane-Cafea",
        "Pahar-Espresso-4-Oz",
        "Capac-Espresso-4-Oz",
        "Pahar-Doppio-7-Oz",
        "Capac-Doppio-7-Oz",
        "Pahar-S-8-Oz",
        "Capac-S-8-Oz",
        "Pahar-M-12-Oz",
        "Pahar-L-16-Oz",
        "Pahar-XL-22-Oz",
        "Capac-M-20-Oz",
        "Pahar-Frappe-M",
        "Pahar-Frappe-L",
        "Capac-Frappe",
        "Paie-Groase",
        "Paie-Subtiri",
        "Macarons",
        "Creme-Brulee",
        "Fistic",
        "Macadamia",
        "Hazelnut",
        "Roasted",
        "Salted-Caramel",
        "Crunch-My-Toffee",
        "Irish",
        "Tiramisu",
        "Amaretto",
        "Royal-Mint",
        "Dulce-De-Leche",
        "Chocolate-Cookie",
        "Rom",
        "Marshmallow",
        "Pumpkin-Spice",
        "Cinnamon",
        "Mojito",
        "No-Sugar-Vanilla",
        "No-Sugar-Caramel",
        "Vanilla",
        "Cocos",
        "Caramel",
        "Caramel-25-cl",
        "Salted-Caramel-25-cl",
        "Vanilie-25-cl",
        "Cocos-25-cl",
        "Tiramisu-25-cl",
        "Irish-25-cl",
        "Hazelnut-25-cl",
        "Fenti-Cherry",
        "Fenti-Cola",
        "Fenti-Rose",
        "Mellow-Orange",
        "Mellow-Mango",
        "Mellow-Mere",
        "Fritz-Cola",
        "Fritz-Portocale",
        "Jumex-Capsune",
        "Jumex-Cocos",
        "Jumex-Mango",
        "Rubi-Mango",
        "Rubi-Rodie",
        "Rubi-Passion",
        "Dr-Pepper-Regular",
        "Dr-Pepper-Cherry",
        "Ginger-Beer",
        "Energizante",
        "Figa",
        "Apa-Tonica",
        "Apa-Plata",
        "Apa-Minerala",
        "Ceai-Plic-Breakfast",
        "Ceai-Plic-EarlGrey",
        "Ceai-Plic-Menta",
        "Ceai-Plic-Jasmin",
        "Ceai-Plic-Verde",
        "Ceai-Plic-Cham",
        "Ceai-Plic-Piersica",
        "Ceai-Plic-Berries",
        "Scortisoara",
        "Nucsoara",
        "Cocos-Condiment",
        "Ghimbir",
        "Cayenne-Pepper",
        "Turmeric",
        "Stevia",
        "Zaharina",
        "Cardamon",
        "HazelnutAlmonds",
        "HazelnutCashews",
        "AlmondsTahini",
        "AlmondsAloe",
        "AlmondsPlums",
        "AlmondsCocoa",
        "AlmondsFigs",
        "OatCherryYogurt",
        "OatTripleChocolate",
        "OatStrawberryCheesecake",
        "OatBlueberries",
        "OatWalnuts",
        "OatBanana",
        "OatChocoCoconut",
        "OatChocoChip",
        "CookiesCream",
        "KetoCookiesCream",
        "ActiveDoubleChocolate",
        "BoostChocolateChip",
        "BoostDoubleChocolateBlueberry",
        "SlimCookiesCream",
        "BoostDoubleChocolateChip",
        "DeluxeChocolateChip",
        "DeluxeCookiesCream",
    ];


    const form = useRef();
    const [valueForm, setValueForm] = useState('');


    const categorizedItems = {

        "Materie Prima": ["Cafea",
            "Cafea-Decofeinizata",
            "Cafea-250g",
            "Inghetata",
            "Gheata",
            "Topping-Caramel",
            "Topping-Ciocolata",
            "Frisca",
            "Ciocolata-Neagra",
            "Ciocolata-Alba",
            "Ciocolata-Lapte",
            "Macha-Organic",
            "Macha-Sweetened",
            "Power-Chai",
            "Spiced-Chai",
            "Chichory-Latte",
            "Zahar-Alb",
            "Zahar-Brun",
            "Bomboane-Cafea",
            "Ceai-Plic-Breakfast",
            "Ceai-Plic-EarlGrey",
            "Ceai-Plic-Menta",
            "Ceai-Plic-Jasmin",
            "Ceai-Plic-Verde",
            "Ceai-Plic-Cham",
            "Ceai-Plic-Piersica",
            "Ceai-Plic-Berries",
        ],

        "Consumabile Non Food": ["Pungi-Banana-Bread",
            "Pungi-Cookies",
            "Role-Casa",
            "Role-POS",
            "Paletine",
            "Lavete-Micro",
            "Lavete-Burete",
            "Burete-Abraziv",
            "Manusi",
            "Bicarbonat",
            "Otet",
            "Detergent-Maini",
            "Detergent-Podele",
            "Spirt",
            "Uleiuri-Esentiale",
            "Detergent-Grupuri",
            "Saci-120L",
            "Saci-60L",
            "Servetele",
            "Paie-Groase",
            "Paie-Subtiri",
            "Scortisoara",
            "Nucsoara",
            "Cocos-Condiment",
            "Ghimbir",
            "Cayenne-Pepper",
            "Turmeric",
            "Stevia",
            "Zaharina",
            "Cardamon",
        ],

        "Siropuri": [
            "Creme-Brulee",
            "Macarons",
            "Fistic",
            "Macadamia",
            "Roasted-Special",
            "Hazelnut",
            "Vanilla",
            "Caramel",
            "Salted-Caramel",
            "Irish",
            "Tiramisu",
            "Crunch-My-Toffee",
            "Cocos",
            "Amaretto",
            "Royal-Mint",
            "Dulce-De-Leche",
            "Chocolate-Cookie",
            "Rom",
            "Marshmallow",
            "Pumpkin-Spice",
            "Cinnamon",
            "Mojito",
            "No-Sugar-Vanilla",
            "No-Sugar-Caramel",
            "Caramel-25-cl",
            "Salted-Caramel-25-cl",
            "Vanilla-25-cl",
            "Cocos-25-cl",
            "Tiramisu-25-cl",
            "Irish-25-cl",
            "Hazelnut-25-cl",
        ],

        "Pahare": [
            "Pahar-Espresso-4-Oz",
            "Capac-Espresso-4-Oz",
            "Pahar-Doppio-7-Oz",
            "Capac-Doppio-7-Oz",
            "Pahar-S-8-Oz",
            "Capac-S-8-Oz",
            "Pahar-M-12-Oz",
            "Pahar-L-16-Oz",
            "Pahar-XL-22-Oz",
            "Capac-M-20-Oz",
            "Pahar-Frappe-M",
            "Pahar-Frappe-L",
            "Capac-Frappe",
        ],

        "Drinks": [
            "Fenti-Cherry",
            "Fenti-Cola",
            "Fenti-Rose",
            "Mellow-Orange",
            "Mellow-Mango",
            "Mellow-Mere",
            "Fritz-Cola",
            "Fritz-Portocale",
            "Jumex-Capsune",
            "Jumex-Cocos",
            "Jumex-Mango",
            "Rubi-Mango",
            "Rubi-Rodie",
            "Rubi-Passion",
            "Dr-Pepper-Regular",
            "Dr-Pepper-Cherry",
            "Ginger-Beer",
            "Energizante",
            "Figa",
            "Apa-Tonica",
            "Apa-Plata",
            "Apa-Minerala",
        ],

        "Snacks & Sweets": [
            "Banana-Bread",
            "Hazelnut-Almonds",
            "Hazelnut-Cashews",
            "Almonds-Tahini",
            "Almonds-Aloe",
            "Almonds-Plums",
            "Almonds-Cocoa",
            "Almonds-Figs",
            "FJ-Cherry-Yogurt-1038",
            "FJ-Tripple-Chocolate-1041",
            "FJ-Strawberry-Cheese-1036",
            "FJ-Blueberries-1033",
            "FJ-Walnuts-1032",
            "FJ-Banana-1040",
            "FJ-Chocolate-Chip-1034",
            "FJ-Cookies-Cream-1039",
            "Fj-Choco-Coconut-1035",
            "Keto-Cookies-Cream-BW-2025",
            "Activ-Double-Chocolate-BW-2024",
            "Boost-Chocolate-Chip-BW-2022",
            "Boost-Double-Chocolate-Blueberry-BW-2023",
            "Slim-Cookies-Cream-BW-2026",
            "Slim-Double-Chocolate-Chip-BW-2027",
            "Deluxe-Chocolate-Chip-BW-2028",
            "Deluxe-Cookies-Cream-BW-2029",]
    };


    const [checkedItems, setCheckedItems] = useState({});

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_s444axq', 'template_rpiry6d', form.current, '7M4Cj0uk_09UOe3du')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        console.log(checkedItems)
        form.current.submit();
    };

    const [inputValues, setInputValues] = useState({});





    const generateJson = () => {
        const jsonObject = {};

        Object.keys(categorizedItems).forEach((category) => {
            categorizedItems[category].forEach((item) => {
                const inputValue = inputValues[category] && inputValues[category][item] ? inputValues[category][item] : 0;
                const isChecked = checkedItems[category] && checkedItems[category][item] === 'URGENT';

                jsonObject[item] = {
                    value: inputValue,
                    urgent: isChecked ? 'URGENT' : '',
                };
            });
        });

        return jsonObject;
    };



    // Create the JSON data
    const jsonData = JSON.stringify(generateJson());

// ... (your form JSX)

// Add a hidden input field to send JSON data


    const handleSaveData = async () => {
        const jsonObject = {
            data: data,
            cafea: cafea,
            lapte: lapte,
        };

        try {
            const response = await fetch('https://roasted.ro/comanda_edgar.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*', // Set this header if the server allows all origins (*), otherwise set it to your server's domain.
                },
                body: JSON.stringify(jsonObject),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const responseData = await response.json();
            console.log(responseData); // Response data from the server, if any.
            // Handle success or show a success message to the user.
        } catch (error) {
            console.error('Error:', error);
            // Handle error or show an error message to the user.
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Handle form submission logic
        // Call handleSaveData and sendEmail functions here
        await handleSaveData();
        await sendEmail(event);
    };

    // onSubmit={sendEmail}



    const [selectedItem, setSelectedItem] = useState(null);


    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleDropdownChange = (event) => {
        const newCategory = event.target.value;
        setSelectedCategory(newCategory);
        setInputValues({
            ...inputValues,
            [selectedCategory]: {
                ...inputValues[selectedCategory],
            },
        });
        setCheckedItems({
            ...checkedItems,
            [selectedCategory]: {
                ...checkedItems[selectedCategory],
            },
        });
    };


    const handleInputChange = (e, category, item) => {
        const updatedInputValues = {
            ...inputValues,
            [category]: {
                ...inputValues[category],
                [item]: e.target.value,
            },
        };
        setInputValues(updatedInputValues);
    };

    const handleCheckboxChange = (category, item) => {
        const newCheckedItems = {
            ...checkedItems,
            [category]: {
                ...(checkedItems[category] || {}),
                [item]: checkedItems[category] && checkedItems[category][item] === 'URGENT' ? '' : 'URGENT',
            },
        };
        setCheckedItems(newCheckedItems);
    };


    // const handleCheckboxChange = (itemName) => {
    //     const itemIndex = itemList.indexOf(itemName);
    //     if (itemIndex !== -1) {
    //         const newCheckedItems = [...checkedItems];
    //         if (newCheckedItems[itemIndex] === '') {
    //             newCheckedItems[itemIndex] = 'URGENT';
    //         } else if (newCheckedItems[itemIndex] === 'URGENT') {
    //             newCheckedItems[itemIndex] = '';
    //         }
    //         setCheckedItems(newCheckedItems);
    //     }
    // };
    //
    // const handleCheckboxChange = (category, item) => {
    //     setCheckedItems((prevCheckedItems) => ({
    //         ...prevCheckedItems,
    //         [category]: {
    //             ...prevCheckedItems[category],
    //             [item]: prevCheckedItems[category][item] === 'URGENT' ? '' : 'URGENT',
    //         },
    //     }));
    // };


    const [showFormSand, setShowFormSand] = useState(false);
    const formSandwich = useRef()




    const [ham, setHam] = useState(0)
    const [mediterana, setMediterana] = useState(0)
    const [pui, setPui] = useState(0)




    const changeHam = e => {
        setHam(e.target.value)
    }


    const changeMediterana = e => {
        setMediterana(e.target.value)
    }


    const changePui = e => {
        setPui(e.target.value)
    }


    const handleButtonClickSand = () => {
        setShowFormSand(!showFormSand);
    };






    const sendEmailSand = (e) => {
        e.preventDefault();


        emailjs.sendForm('service_s444axq', 'template_u3u041k', formSandwich.current, '7M4Cj0uk_09UOe3du')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        console.log(checkedItems)
        formSandwich.current.submit();
    };


    return(
        <div>
            <Navbar/>
            <div className="conta-title-batistei">
                Contabilitate Edgar
            </div>
            <div className="conta-login"  style={{
                height: showForm ? '7280px' : '850px',
                marginTop: showForm ? '3600px' : '100px'
            }}>
                <div className="conta-content-batistei" style={{}}>
                    <form action="https://roasted.ro/insert_edgar.php" method="post">
                        <p className="no-wrap-data">
                            <label className="label-conta-batistei-data" style={{marginTop: "40px"}}>
                                Data
                            </label>
                            <input onChange={changeData} className="margin-label-conta" type="date" name="data"
                                   id="data" defaultValue={currentDate}
                                   value={data} style={{padding: "2px 20px", color: "black"}}/>
                        </p>
                        <div className="conta-line-data"></div>
                        <p className="no-wrap">
                            <label className="label-conta-batistei-cafea">
                                Cafea
                            </label>
                            <input onChange={changeCafea} className="margin-label-conta" type="text" name="cafea"
                                   value={cafea}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-conta-batistei-lapte">
                                Lapte
                            </label>
                            <input onChange={changeLapte} className="margin-label-conta" type="int" name="lapte"
                                   value={lapte}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-conta-batistei-alt">
                                Alternativ
                            </label>
                            <input onChange={changeAlternativ} className="margin-label-conta" type="int"
                                   name="lapte_alternativ"
                                   value={alternativ}/>
                        </p>
                        <p className="no-wrap">
                            <label className="label-conta-batistei-cash">
                                Cash
                            </label>
                            <input onChange={changeCash} className="margin-label-conta" type="int" name="cash"
                                   value={cash}/>
                        </p>

                        <p className="no-wrap">
                            <label className="label-conta-batistei-card">
                                Card
                            </label>
                            <input onChange={changeCard} className="margin-label-conta" type="int" name="card"
                                   value={card}/>
                        </p>

                        <p className="no-wrap">
                            <label className="label-conta-batistei-bonuri">
                                Bonuri
                            </label>
                            <input onChange={changeBonuri} className="margin-label-conta" type="int" name="bonuri"
                                   value={bonuri}/>
                        </p>

                        <br/>
                        <p>
                            <button type="submit" className="margin-label-submit-conta">Inregistreaza</button>
                            {/*<input  className="margin-label-submit-conta" type="submit" value={submitText} />*/}
                        </p>
                    </form>
                    <p>
                        <button className="margin-label-submit-conta" onClick={handleButtonClick}>
                            {showForm ? 'Inchide Formular' : 'Arata Formular'}
                        </button>
                    </p>
                    <p>
                        <button className="margin-label-submit-conta" onClick={handleButtonClickSand}
                                style={{backgroundColor: "green", color: "white"}}>
                            {showFormSand ? 'Inchide Comanda Sandwich' : 'Comanda Rapida Sandwich'}
                        </button>
                        {showFormSand && (<div style={{
                            border: "1px solid green",
                            width: "90%",
                            marginLeft: "5%",
                            marginRight: "5%",
                            marginTop: "20px"
                        }}>
                            <form
                                // action="https://roasted.ro/comenzi_sandwich.php" method="post"
                                ref={formSandwich}
                                onSubmit={sendEmailSand}
                                style={{textAlign: "center", marginRight: "25px"}}>
                                <p className="no-wrap-data">
                                    <label className="label-conta-batistei-cafenea" style={{marginTop: "10px"}}>
                                        Locatie
                                    </label>
                                    <input onChange={changeCafenea} className="margin-label-conta-form" type="text"
                                           name="locatie" id="locatie" defaultValue="EDGAR"
                                           style={{padding: "2px 20px"}}/>
                                </p>
                                <p className="no-wrap-data" style={{marginTop: "-10px"}}>
                                    <label className="label-conta-batistei-data"
                                           style={{marginTop: "5px", marginLeft: "25px"}}>
                                        Croissant
                                    </label>
                                    <input onChange={changeHam} className="margin-label-conta" type="text"
                                           name="ham"
                                           id="ham" defaultValue={0}
                                           value={ham} style={{
                                        padding: "2px 10px",
                                        color: "black",
                                        width: "70px",
                                        textAlign: "center"
                                    }}/>
                                </p>
                                <p className="no-wrap-data" style={{marginTop: "-10px"}}>
                                    <label className="label-conta-batistei-data"
                                           style={{marginTop: "5px", marginLeft: "32px"}}>
                                        Ciabatta
                                    </label>
                                    <input onChange={changeMediterana} className="margin-label-conta" type="text"
                                           name="mediterana"
                                           id="mediterana" defaultValue={0}
                                           value={mediterana} style={{
                                        padding: "2px 10px",
                                        color: "black",
                                        width: "70px",
                                        textAlign: "center"
                                    }}/>
                                </p>
                                <p className="no-wrap-data" style={{marginTop: "-10px"}}>
                                    <label className="label-conta-batistei-data"
                                           style={{marginTop: "5px", marginLeft: "76px"}}>
                                        Pui
                                    </label>
                                    <input onChange={changePui} className="margin-label-conta" type="text"
                                           name="pui"
                                           id="pui" defaultValue={0}
                                           value={pui} style={{
                                        padding: "2px 10px",
                                        color: "black",
                                        width: "70px",
                                        textAlign: "center"
                                    }}/>
                                </p>
                                <button type="submit" className="margin-label-submit-conta-sandwich">Inregistreaza
                                    Comanda
                                </button>
                            </form>
                        </div>)}
                    </p>

                    <br/>
                    {showForm && (
                        <div>
                            <form action="https://roasted.ro/comenzi_cafenele.php" method="post" ref={form}
                                  onSubmit={sendEmail}
                                  style={{textAlign: "center", marginRight: "25px"}}>
                                <p className="no-wrap-data">
                                    <label className="label-conta-batistei-cafenea" style={{marginTop: "40px"}}>
                                        Cafenea
                                    </label>
                                    <input onChange={changeCafenea} className="margin-label-conta-form" type="text"
                                           name="locatie" id="locatie" defaultValue="Batistei"
                                           style={{padding: "2px 20px"}}/>
                                </p>
                                <p className="no-wrap-data">
                                    <label className="label-conta-batistei-data"
                                           style={{marginTop: "10px", marginLeft: "25px"}}>
                                        Data
                                    </label>
                                    <input onChange={changeData} className="margin-label-conta" type="date" name="data"
                                           id="data" defaultValue={currentDate}
                                           value={data} style={{padding: "2px 20px", color: "black"}}/>
                                </p>
                                <p className="no-wrap-data" style={{marginLeft: "30px"}}>
                                    <b>* bifeaza comenzile urgente *</b>
                                </p>
                                {/*{itemList.map((item, index) => (*/}
                                {/*    <p className={`no-wrap-${item}`} key={item} style={{margin: "10px"}}>*/}
                                {/*        <label className={`label-conta-batistei-${item}-form`} style={{marginRight: "10px"}}>*/}
                                {/*            {item}*/}
                                {/*        </label>*/}
                                {/*        <input*/}
                                {/*            onChange={e => handleInputChange(e, item)}*/}
                                {/*            className="margin-label-conta-form1"*/}
                                {/*            type="text"*/}
                                {/*            name={item}*/}
                                {/*            defaultValue="0"*/}
                                {/*        />*/}
                                {/*        <input*/}
                                {/*            name={`urgent${index}`}*/}
                                {/*            type="checkbox"*/}
                                {/*            value={checkedItems[index]}*/}
                                {/*            onChange={() => handleCheckboxChange(index)}*/}
                                {/*            style={{ width: "25px", height: "25px", position: "absolute" }}*/}
                                {/*        />*/}
                                {/*    </p>*/}
                                {/*))}*/}
                                <div>
                                    <div>
                                        <select className="select-category" onChange={handleDropdownChange}>
                                            <option value="" className="select-category">Selecteaza Categoria</option>
                                            {Object.keys(categorizedItems).map((category, categoryIndex) => (
                                                <option className="select-category" key={categoryIndex}
                                                        value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedCategory && (
                                        <div>
                                            <br/>
                                            <h3 style={{marginLeft: "20px"}}>{selectedCategory}</h3>
                                            <br/>
                                            {categorizedItems[selectedCategory].map((item, index) => (
                                                <div key={index} className={`no-wrap-${item}`} style={{margin: "10px"}}>
                                                    <label className={`label-conta-batistei-${item}-form`}
                                                           style={{marginRight: "10px"}}>
                                                        {item}
                                                    </label>
                                                    <input
                                                        onChange={(e) => handleInputChange(e, selectedCategory, item)}
                                                        className="margin-label-conta-form1"
                                                        type="text"
                                                        name={item}
                                                        value={inputValues[selectedCategory] && inputValues[selectedCategory][item] ? inputValues[selectedCategory][item] : ''}
                                                    />
                                                    <input
                                                        name={`urgent-${item}`}
                                                        type="checkbox"
                                                        checked={checkedItems[selectedCategory] && checkedItems[selectedCategory][item] === 'URGENT'}
                                                        onChange={() => handleCheckboxChange(selectedCategory, item)}
                                                        style={{width: "25px", height: "25px", position: "absolute"}}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <input type="hidden" name="comanda" value={jsonData}/>
                                <br/>
                                <textarea
                                    value={text}
                                    onChange={handleChange}
                                    rows={4}
                                    cols={50}
                                    placeholder="Aici poti introduce detalii despre comanda"
                                    className="custom-textarea"
                                    name="detalii"
                                />
                                <br/>
                                <br/>
                                {/*type="submit"*/}
                                <button type="submit" className="margin-label-submit-conta"
                                        style={{marginLeft: "30px"}}>Trimite comanda
                                </button>
                                {/*<input  className="margin-label-submit-conta" type="submit" value={submitText} />*/}
                                <br/>
                            </form>
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}